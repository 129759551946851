@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html,
body {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  height: 100%;
  margin: 0;
  overflow-y: auto;
}

#root {
  height: 100%;
}

.loginbackground {
  background-color: #2e75b6;
  color: white;
  min-height: 100%;
  padding-bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h1 {
  text-align: left;
  font-size: 16px;
  color: #0080c1;
}

h2 {
  text-align: left;
  font-size: 14px;
  color: #0080c1;
}

p {
  text-align: left;
}

.login {
  margin: 10px auto;
  width: 350px;
}

.login-screen {
  background-color: #fff;
  padding: 20px;
  border-radius: 3px;
}

.app-title {
  text-align: center;
  color: #777;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10%;
}

.oblogologin {
  object-fit: contain;
  max-width: 100%;
  max-height: 85px;
}

.floatright {
  display: inline;
  float: right;
}

.login-form {
  text-align: left;
  color: #777;
}

.control-group {
  margin-bottom: 10px;
}

input {
  text-align: left;
  background-color: #ecf0f1;
  border: 2px solid transparent;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 200;
  padding: 5px 0px 5px 10px;
  width: 310px;
  transition: border 0.5s;
}

input:focus {
  border: 2px solid #0080c1;
  box-shadow: none;
}

.btnright {
  border: 2px solid transparent;
  background: #f1f1f1;
  color: #0080c1;
  font-size: 12px;
  line-height: 15px;
  padding: 4px 0;
  text-decoration: none;
  text-shadow: none;
  border-radius: 2px;
  box-shadow: none;
  transition: 0.25s;
  display: block;
  clear: both;
  float: right;
  width: 100px;
  margin: 0 auto;
  text-align: center;
}

.btnright:hover {
  background-color: #0080c1;
  color: white;
}

.login-link {
  font-size: 12px;
  color: #444;
  display: block;
  margin-top: 12px;
}

.linknexttobutton {
  padding-top: 10px;
}

.button-group {
  vertical-align: middle;
}

.oceansbluecentrallogo {
  font-size: 26px;
  font-family: sans-serif;
}

.oceansbluefont {
  font-family: sans-serif;
  font-size: 17px;
}

.inputlables {
  padding-right: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  min-height: 28.16px;
}

.pintobottom {
  position: fixed;
  text-align: center;
  bottom: 10px;
  width: 100%;
}
