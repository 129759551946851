/*makes the hover of header rows light blue*/
.k-grid tbody tr.k-grouping-row:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: #e0f3fd;
}

/*done to make the hover on the row work*/
.k-grouping-row td {
  background-color: transparent;
}

.todaytomorrowdarkblue {
  color: darkblue;
}

.todaytomorrowred {
  color: red;
}

.todaytomorrowgrey {
  color: grey;
}

.todaytomorroworange {
  color: orange;
}

.whitenumcell {
  background-color: white;
}

.whitenumcell:hover {
  background-color: #e0f3fd;
}

.lightbluenumcell {
  background-color: #c1d6ea;
}

.rednumcell {
  background-color: #fbbab3;
}

.orangenumcell {
  background-color: #f5a738;
}

.redtextcell {
  color: red;
}

.orangetextcell {
  color: orange;
}
/* grid context menu colours*/
.contextmenu {
  color: white;
  background: #444444;
  font-size: 12px;
}

/* ticks for the match and part match in the grid*/
.greentick {
  color: green;
}

.redtick {
  color: red;
}

.orangetick {
  color: orange;
}

th.k-header.activegreentick .k-grid-column-menu {
  color: green;
}

th.k-header.activeredtick .k-grid-column-menu {
  color: red;
}

.k-grid-header.k-header {
  vertical-align: inherit;
}

th.k-table-th.k-header {
  padding: 4px 12px;
  min-height: 29.14px;
  height: 29.14px;
  margin: 0;
  vertical-align: inherit;
}

/* app bar colouring*/
.k-appbar-light {
  color: white;
  background-color: #2e75b7;
}

.appbar-items .k-item k-state-default {
  background-color: #2e75b7;
}

.k-appbar {
  padding: 0px 16px 0px 7px;
  height: 37px;
}

/* set the radius on the tab bars*/
.k-tabstrip-top > .k-tabstrip-items .k-item {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

/* put the little bar back at the top of the tabs*/
.k-tabstrip-items {
  padding-top: 5px;
}

/* app bar tab colouring*/
.appbartabs .k-tabstrip-items .k-item.k-state-active,
.k-tabstrip-items .k-item.k-state-selected {
  background-color: #68a4d9;
  color: white;
}

.appbartabs .k-tabstrip-items {
  background-color: #2e75b7;
}

.appbartabs .k-tabstrip-items .k-item {
  background-color: #2e75b7;
  color: white;
}

/* app bar  tab hover*/
.appbartabs .k-tabstrip-items .k-item:hover {
  background-color: #3f89cf;
  color: white;
}

/* app bar  tab hover*/
.appbartabs .k-tabstrip-items .k-item.k-state-active:hover {
  background-color: #68a4d9;
}

/* app bar  tab hover*/
.appbartabs .k-tabstrip-items .k-item.k-state-selected:hover {
  background-color: #68a4d9;
}

/* filters bar tab colouring*/
.filterstab .k-tabstrip-items .k-item.k-state-active,
.k-tabstrip-items .k-item.k-state-selected {
  background-color: #f2f2f2;
}

.filterstab .k-tabstrip-items {
  background-color: #cadef1;
}

.filterstab .k-tabstrip-items .k-item {
  background-color: #cadef1;
  color: black;
}

/* Filters tab hover*/
.filterstab .k-tabstrip-items .k-item:hover {
  background-color: #ced2ce;
  color: black;
}

/* Filters tab hover*/
.filterstab .k-tabstrip-items .k-item.k-state-active:hover {
  background-color: #f2f2f2;
}

/* Filters tab hover*/
.filterstab .k-tabstrip-items .k-item.k-state-selected:hover {
  background-color: #f2f2f2;
}

.filterstab .k-tabstrip > .k-content {
  background-color: #f2f2f2;
}

.k-tabstrip > .k-content {
  padding: 0px;
}

/*centers the tabs*/
.k-tabstrip.k-header > ul.k-reset {
  display: flex;
  justify-content: center;
  width: 100%;
}

/*centers the tabs*/
.k-tabstrip.k-header > ul.k-reset > li {
  flex: 0 1 auto;
}

/* hides the content area of fake tabs*/
.hiddentabcontentarea .k-content.k-state-active {
  display: none;
}

/* sets font size for popout windows*/
.k-window-title {
  font-size: 12px;
}

/* grid header colouring*/
.k-grid-header .k-header {
  background-color: #ffffff;
  color: #888;
  border-width: 0px;
}

.k-grid-header tr:first-child th:nth-child(odd) {
  border-left-style: solid;
  border-left-width: 2px;
  border-left-color: #ced2ce;
  background-color: #f7f7f7;
}

.k-grid-header tr:first-child th:nth-child(even) {
  border-left-style: solid;
  border-left-width: 2px;
  border-left-color: #ced2ce;
  background-color: #f7f7f7;
}

.k-grid-header .k-header .k-link {
  padding-left: 5px;
}

/* grid colouring and stopping text wrap*/
.k-grid td {
  border-color: lightgray;
  border-width: 0px 0px 1px 0px;
  white-space: nowrap;
}

/*gets rid of the border*/
.k-grid {
  border-style: none;
}

/* floats the export button to the right*/
.k-header k-grid-toolbar {
  display: inline;
  float: right;
}

/* header wrap white when the columns don't fill the screen width*/
.k-grid .k-grid-header-wrap,
.k-grid table {
  background-color: white;
}

/* makes the arrow on the splitter bigger*/
.k-splitbar-vertical .k-i-arrow-60-up {
  font-size: 18px;
}

/* makes the arrow on the splitter bigger*/
.k-splitbar-vertical .k-i-arrow-60-down {
  font-size: 18px;
}

.filtersview {
  padding-top: 5px;
  background-color: #f2f2f2;
}

.floatright {
  display: inline;
  float: right;
}

.excelbutton {
  margin: 3px;
}

.pane-content {
  padding: 0 10px;
  background-color: #f2f2f2;
}

.namesearch {
  width: 200px;
  font-size: 13px;
}

.searchbutton {
  background-image: linear-gradient(#ced2ce, #ced2ce);
  width: 60px;
  font-size: 18px;
}
.searchbuttonupdate {
  border-color: #656565;
  border-style: solid;
  border-width: 2px;
  background-image: linear-gradient(#ced2ce, #ced2ce);
  width: 60px;
  font-size: 18px;
}

/*redo, excle and settings button*/

.redoButton,
.redoButton3 {
  background-image: linear-gradient(white, white);
  width: 40px;
  font-size: 18px;
  margin-right: 10px;
  border-style: none;
}

.redoButton2 {
  background: transparent;
  width: 40px;
  font-size: 18px;
  margin-right: 10px;
  border-style: none;
}

.redoButton4 {
  background: transparent;
  width: 40px;
  font-size: 18px;
  margin-right: 0px;
  border-style: none;
}

.redoButton5 {
  background-image: linear-gradient(white, white);
  width: 40px;
  font-size: 18px;
  margin: 0 10px;
  border-style: none;
}

.searchbutton:hover {
  background-image: linear-gradient(#919a91, #919a91);
}

.buttons-container-button {
  margin: 7px;
}

.redoButton:hover,
.redoButton2:hover,
.redoButton3:hover,
.redoButton4:hover,
.redoButton5:hover {
  background-image: linear-gradient(#919a91, #919a91);
}

.redoButton:focus,
.redoButton2:focus,
.redoButton3:focus,
.redoButton4:focus,
.redoButton5:focus {
  border-width: 0;
  border-color: transparent;
  box-shadow: 0 0 0 transparent;
}

.wardsearchbutton {
  color: black;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-table-row[aria-rowindex='2'] > .k-filterable.k-table-th.k-header.k-grid-header-sticky {
  padding-right: 0;
}

.k-table-row > .k-table-th.k-header:hover > .k-grid-column-menu {
  display: block;
}

/* a.k-grid-header-menu.k-grid-column-menu {
  margin-left: 5px;
} */

.k-grid th,
.k-grid td {
  font-size: 12px;
}

tr.k-table-row.k-master-row > td {
  padding: 0 8px;
}

.k-loading-image,
.k-loading-color {
  color: #2e75b7;
}

.k-table-row > .k-table-th.k-header:hover > .k-cell-inner > a.k-grid-header-menu.k-grid-column-menu {
  visibility: visible;
}

.k-table-row[aria-rowindex='1'] > .k-filterable.k-table-th.k-header > span.k-cell-inner {
  display: flex;
  justify-content: space-between;
}

.k-column-resizer {
  width: 6px;
  z-index: 999;
}

.k-column-resizer:hover {
  background-color: rgba(30, 30, 30, 0.1);
}

.k-table-tfoot > .k-table-row > td {
  padding: 12px 8px;
}

span.k-cell-inner {
  padding: 4px 0px 4px 12px;
  max-height: 29.14px;
}

a.k-grid-header-menu.k-grid-column-menu {
  visibility: hidden;
}

.k-table-row > th > .k-cell-inner {
  border-right: 0px solid transparent;
}

.k-table-row[aria-rowindex='1'] > .k-table-th.k-header {
  border-right: 2px solid #ced2ce;
  border-left: 0px solid transparent;
}

.k-table-row[aria-rowindex='2'] > .k-table-th.k-header {
  inline-size: 120px;
  overflow: hidden;
}

.footer-total-cell:hover {
  background-color: #d0efff !important;
}

.k-table-row > .k-filterable.k-table-th.k-header {
  text-overflow: unset !important;
  word-wrap: normal;
}

.k-table-row[aria-rowindex='2'] > .k-table-th.k-header:hover {
  border-right: 2px solid #ced2ce;
}

.k-table-row > .k-table-th.k-header:hover {
  background-color: #d0efff;
}

/* removed the line at the top of the splitter so that the tabs flow into the filter area*/
.k-splitter {
  border-top: 0px;
}

/* used for hiding the grid header menus until hover*/
th.k-header > div {
  display: none !important;
}

/* used for hiding the grid header menus until hover*/
th.k-header:hover > div {
  display: inline !important;
}

/* required to set grid row height*/
.k-grid tbody tr {
  line-height: 30px;
}

/* required to set grid row height*/
.k-grid tbody td {
  padding: 0px 12px 0px 12px;
}

/*grid font size*/
.k-grid {
  font-size: 12px;
}

/*height of window title bar*/
.k-window-titlebar {
  padding-bottom: 0px;
  padding-top: 0px;
  background-color: #2e75b7;
}

/*width of date picker*/
.k-datepicker {
  width: 115px;
  color: black;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}

.pinbottom {
  position: absolute;
  bottom: 5px;
}

.pintobottom {
  position: fixed;
  text-align: center;
  bottom: 10px;
  width: 100%;
}

.clear {
  left: calc(100% - 125px);
}

.closelocationtree {
  left: calc(100% - 60px);
}

.locationstreeview {
  position: relative;
  height: auto;
  max-height: 600px;
  overflow-x: hidden;
  padding: 0px 5px 0px 5px;
}

.rowcount {
  padding-right: 10px;
  overflow: hidden;
  white-space: nowrap;
}

.toggle-button {
  opacity: 1;
}

.toggle-button:hover {
  opacity: 0.8;
}

.staffdetailgridbutton {
  background-image: linear-gradient(#f1f2f1, #f1f2f1);
  color: #656565;
}

.staffdetailgridbutton:hover {
  background-image: linear-gradient(#ced2ce, #ced2ce);
  color: #656565;
}

.gridfiltericon {
  color: #656565;
}

.gridheaderlink {
  color: blue;
}

.trustpadding {
  padding-right: 10px;
  padding-left: 10px;
}

.trustpadding a {
  color: white;
}

.k-notification {
  font-size: larger;
}

.k-notification-group {
  z-index: 99999999999999999999;
}

.k-notification-success {
  border-color: #2e75b7;
  background-color: #2e75b7;
}

.filterwardunit {
  width: calc(100% - 80px);
}

.reportview {
  padding-left: 15px;
}

.reportsviewer {
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.helpviewer {
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.helpviewer td {
  vertical-align: top;
}

/*remove the borders on locked coumns*/
.k-master-row .k-grid-content-sticky {
  border-right-width: 0px;
  border-color: lightgray;
  border-top-color: rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

/*remove the borders on locked coumns*/
.k-grid-footer .k-grid-footer-sticky {
  border-right-width: 0px;
  border-color: lightgray;
  border-top-color: rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

/*override the button group to make the first button have rounded corners*/
.k-button-group .k-group-start,
.k-button-group .k-button:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

/* .k-button-solid-base:focus {
} */

.date-picker-button-solid-base:focus {
  border-color: transparent;
  box-shadow: none;
  border: none;
}

.date-picker-button {
  background: white;
  /* border: 2px solid white; */
  padding: 2px 5px;
}

.search-button {
  background: rgb(220, 218, 218);
}

.search-button:hover {
  background: rgb(204, 202, 202);
}

.date-picker-button:hover {
  background: rgba(208, 239, 255, 0.5);
}

.k-dateinput:focus,
.k-input-inner:focus {
  outline: none;
  border: none;
}

/*override the button group to make the last button have rounded corners*/
.k-button-group .k-group-end,
.k-button-group .k-button:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.k-button-group .k-button.k-state-active,
.k-button-group .k-button.k-state-selected,
.k-button-group .k-button:active,
.k-button-group > input[type='checkbox']:checked + .k-button,
.k-button-group > input[type='radio']:checked + .k-button {
  border-color: #2e75b7;
  background-color: #2e75b7;
}

.k-grouping-header {
  padding: 0 8px;
}

.k-header > .k-cell-inner {
  margin: -8px -16px;
}

.firstfilterrow {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  padding-top: 15px;
  gap: 7rem;
}

@media (min-width: 1401px) {
  .firstfilterrow {
    gap: 5rem;
  }
}

@media (min-width: 1500px) {
  .firstfilterrow {
    gap: 6rem;
  }
}

@media (min-width: 1750px) {
  .firstfilterrow {
    gap: 10rem;
  }
}

@media (min-width: 1850px) {
  .firstfilterrow {
    gap: 15rem;
  }
}

@media (min-width: 2000px) {
  .firstfilterrow {
    gap: 20rem;
  }
}

@media (max-width: 1400px) {
  .firstfilterrow {
    gap: 3rem;
  }
}

@media (max-width: 1315px) {
  .firstfilterrow {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .wardsearchbutton {
    max-width: 420px;
  }
}

.secondfilterrow {
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: white;
}

.width100 {
  width: 100%;
}

.shiftdetailsviewerheadingdiv {
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  background-color: lightgrey;
}

.shiftdetailsviewerheadingdiv h2 {
  font-size: 14px;
}

.shiftdetailsviewerdiv {
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.shiftdetailsviewersurround {
  padding: 5px;
}

.panelbar-wrapper {
  padding-left: 40px;
  margin: 0 auto;
}

.helpdiv {
  padding-left: 30px;
}

.k-panelbar-item-text {
  font-style: italic;
}

.metricstable td {
  padding-left: 10px;
}

.metricstable th {
  padding-left: 10px;
}

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checklist table,
.checklist tr,
.checklist th {
  border: 3px solid white;
  text-align: center;
  font-family: Helvetica;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  table-layout: fixed;
}

.checklist td {
  border-left: 3px solid white;
  border-right: 3px solid white;
  border: none;
  text-align: center;
  font-family: Helvetica;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.topheader {
  background: LightGrey;
  color: black;
  text-transform: uppercase;
}

.topheader,
.topheader2 {
  text-align: center;
  width: 150px;
}

.topheader2 {
  background: #2e75b7;
  color: white;
  text-transform: capitalize;
}

.window-open-link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}
